<template>
	<edit-template type="card">

    <el-card shadow="never" class="border-none mb-2">
      <template #header=""><span class="tb">基础信息：</span></template>
      <LineWrapper labelwidth="150rem" class="pl-3">
				<LineItem v-for="(item, index) in list1" :key="index" :label="item.label + '：'" :value="item.value" labelWidth="160rem"></LineItem>
      </LineWrapper>
    </el-card>
    <el-card shadow="never" class="border-none mb-2">
      <template #header=""><span class="tb">缴费详情：</span></template>
      <el-form class="ml-3" :model="formData" :rules="rules" ref="ruleForm" label-width="150rem" label-position="left">
        <el-form-item label="应缴总金额" prop="payable_amount">
          <el-input type="number" v-model="formData.payable_amount" placeholder="请输入应缴总金额"></el-input>
        </el-form-item>
        <el-form-item label="更改总金额原因" prop="reason" style="margin-right: 300rem;" v-if="showReason">
          <el-input type="textarea" v-model="formData.reason" :maxlength="100" show-word-limit="" :autosize="{ minRows: 4, maxRows: 6 }" placeholder="请输入"></el-input>
        </el-form-item>
        <template v-if="!isFirst">
          <el-form-item label="累计实缴总金额：" prop="arrears11111">
            <span>{{ oldValue.paidin_amount }}</span>
          </el-form-item>
          <el-form-item label="当前欠缴总金额：" prop="arrears22222">
            <span>{{ showTotalArrears }}</span>
          </el-form-item>
        </template>
        <el-form-item label="实缴金额：" prop="paidin_amount">
          <el-input type="number" v-model="formData.paidin_amount" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="欠缴总金额：" prop="outstanding_amount">
          <span>{{ showArrears }}</span>
        </el-form-item>
        <el-form-item label="缴费时间" prop="payment_time">
          <el-date-picker v-model="formData.payment_time" :picker-options="pickerOptions" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <template slot="footer">
      <el-button type="primary" @click="handleSubmit('ruleForm')">保存</el-button>
      <el-button @click="handleVisible(0)">缴费记录</el-button>
      <el-button @click="handleVisible(1)">变更记录</el-button>
      <el-button @click="handleBack">返回</el-button>
    </template>
    <Dialog :visible.sync="visible" :title="title" :showFooter="false" showLine>
      <timeline :list="list2" reverse="" v-if="!type">
        <template #[index]="" v-for="(item, index) in list2">
          <PaymentItem :item="item"></PaymentItem>
        </template>
      </timeline>
      <timeline :list="list3" reverse="" v-else=""></timeline>
    </Dialog>

</edit-template>
</template>

<script>
import Dialog from "@/components/common/Dialog";
import TimeLine from "@/components/common/TimeLine";
import LineItem from "@/components/common/LineItem";
import LineWrapper from "@/components/common/LineWrapper";
import PaymentItem from "../components/PaymentItem";
import {as2_pay_history, as2_pay_record, as2_pay_show, as2_pay_update} from "@/api";
import {formDataBackMixin, pageLoadingMixin} from '@/utils/mixins'

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  components: { Dialog, TimeLine, LineItem, LineWrapper, PaymentItem },
  mixins: [formDataBackMixin, pageLoadingMixin],
  data() {
    const validateTotal = (rule, value, callback) => {
      this.$refs.ruleForm.validateField('outstanding_amount')
      if (value === '') {
        callback(new Error('请输入应缴总金额'))
      } else if(Number(value) < 0){
        callback(new Error('应缴总金额必须为大于等于0的数值'))
      } else {
        if(this.showReason) {
          this.$refs.ruleForm.validateField('paidin_amount')
          this.$refs.ruleForm.validateField('payment_time')
        }
        if(this.oldValue.paidin_amount && this.oldValue.paidin_amount > Number(value)) {
          callback(new Error('应缴总金额必须为大于已实缴金额'))
        }
        const str = value + ''
        const arr = str.split('.')
        if(arr[1] && arr[1].length > 2) {
          callback(new Error('最多输入2位小数'))
        }
        callback();
      }
    }
    const validatePay = (rule, value, callback) => {
      this.$refs.ruleForm.validateField('outstanding_amount')
      if (value === '') {
        this.showReason ? callback() : callback(new Error('请输入实缴金额'))
      } else if(Number(value) < 0){
        callback(new Error('实缴金额必须为大于等于0的数值'))
      } else {
        const str = value + ''
        const arr = str.split('.')
        if(arr.length > 1 && arr[1].length > 2) {
          callback(new Error('最多输入2位小数'))
        }
        const n1 = Number(value) * this.num
        const n2 = Number(this.formData.payable_amount) * this.num
        const n3 = Number(this.oldValue.paidin_amount) * this.num
        const num = (n2 - n1 - n3) / this.num
        num < 0 ? callback(new Error(`实缴金额${n3 ? '+累计实缴金额' : ''}不能大于应缴总金额`)) : callback()
      }
    }
    const validateArrears = (rule, value, callback) => {
      if (value === '') {
        callback();
      } else if(Number(value) < 0){
        callback(new Error('欠缴金额不能小于0'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      title: '',
      type: 0,
      list1: [],
      list2: [],
      list3: [],
      isFirst: true,
      num: 1000,
      total: 0,
      oldValue: {
        paidin_amount: 0,
        outstanding_amount: 0,
      },
      formData: {
        payable_amount: '',
        paidin_amount: '',
        payment_time: '',
        reason: '',
        outstanding_amount: '',
      },
      rules: {
        payable_amount: [{ required: true, validator: validateTotal, trigger: 'change' }],
        paidin_amount: [{ required: true, validator: validatePay,  trigger: 'change' }],
        payment_time: [{ required: true, message: '请选择日期', trigger: 'change' }],
        reason: [{ required: true, message: '请输入更改原因', trigger: 'blur' }],
        outstanding_amount: [{ required: false, validator: validateArrears, trigger: 'change' }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    }
  },
  computed: {
    showArrears() {
      if(this.formData.payable_amount === '' || !this.formData.paidin_amount) {
        this.formData.outstanding_amount = ''
        return ''
      }
      const num1 =  Number(this.formData.payable_amount) * this.num
      const num2 = Number(this.formData.paidin_amount) * this.num
      const num3 = Number(this.oldValue.paidin_amount) * this.num
      this.formData.outstanding_amount = (num1 - num2 - num3) / this.num
      return this.formData.outstanding_amount
    },
    showTotalArrears() {
      let arrears = 0
      //  || !this.formData.paidin_amount
      if(!this.formData.payable_amount) {
        arrears = this.oldValue.outstanding_amount
      } else {
        const num1 =  Number(this.formData.payable_amount) * this.num
        // const num2 = Number(this.formData.paidin_amount) * this.num
        const num3 = Number(this.oldValue.paidin_amount) * this.num
        arrears = (num1 - num3) / this.num
      }
      return arrears
    },
    showTotalPay() {
      let pay = 0
      if(!this.formData.paidin_amount) {
        pay = this.oldValue.paidin_amount
      } else {
        const num1 =  Number(this.formData.paidin_amount) * this.num
        const num2 =  Number(this.oldValue.paidin_amount) * this.num
        pay = (num1 + num2 )/ this.num
      }
      return pay
    },
    showReason() {
      if(!this.rules.paidin_amount[0].required) {
        this.rules.paidin_amount[0].required = true
        this.rules.payment_time[0].required = true
      }
      if(Number(this.formData.payable_amount) !== this.total) {
        this.rules.paidin_amount[0].required = false
        this.rules.payment_time[0].required = false
        return true
      }
      return false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.httpStart()
      as2_pay_show({ id: this.$route.query.id }).then(res => {
        this.list1 = [
          { label: '学生姓名', value: res.student_name },
          { label: '学号', value: res.student_no },
          { label: '校区', value: res.school },
          { label: '年级', value: res.grade },
          { label: '转入行政班', value: res.class },
          { label: '学习方向', value: res.direction },
          { label: '专业方向', value: res.major },
					{label: '转入艺体时间', value: res.art_at},
          { label: '转入审批通过时间', value: res.approve_time }
        ]
        this.formData.payable_amount = this.total = +res.payable_amount
        this.oldValue.paidin_amount = +res.paidin_amount
        this.oldValue.outstanding_amount = +res.outstanding_amount
        if(res.paidin_amount) {
          this.isFirst = false
        }
      }).finally(() => this.httpEnd())

      as2_pay_record({ id: this.$route.query.id }).then(res => {
        const list = res.map(item => ({ date: item.pay_at, total: item.paidin_amount, fee: item.outstanding_amount, action: item.creator, time: item.created_at }))
        this.list2 = list
      })
      as2_pay_history({ tbl_id: this.$route.query.id, tbl_name: 'art_pay' }).then(res => {
        const { list = [] } = res
        this.list3 = list.map(i => ({ content: i.change_detail }))
      })
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { reason, ...other } = this.formData
          const data = { id: this.$route.query.id, ...other }
          if(this.showReason) {
            data.reason = reason
          }
          if(reason) {
            if(data.paidin_amount && !data.payment_time) {
              return this.$message.error('请选择缴费时间')
            }
            if(!data.paidin_amount && data.payment_time) {
              return this.$message.error('请填写实缴金额')
            }
          }
          as2_pay_update(data).then(res => {
            this.$message.success('保存成功')
            if(this.showArrears === 0) {
              this.$router.replace('/finance/arts-sports/payment/detail?id=' + this.$route.query.id)
            } else {
              this.$router.back()
            }
          })
        }
      });
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
    },
    handleVisible(type) {
      this.title = type ? '变更记录' : '缴费记录'
      this.visible = true
      this.type = type
    },
    handleBack() {
      if(Number(this.formData.payable_amount) === this.total) {
        this.formBack(this.formData, ['payable_amount'])
      } else {
        this.formBack(this.formData)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
